import React from "react"
import Layout from "../components/App/Layout"
import PageBannerBackground from "../components/Common/PageBannerBackground"
import KidsHome from "../components/Kids/KidsHome"
import ExploreKids from "../components/Kids/ExploreKids"

// Functional component
const Kids = () => {
  return (
    <Layout page="Kids">
      <PageBannerBackground
        pageTitle="KIDS"
        crumbs={[{page: "Home", url: "/"}, {page: "Courses"}]} 
        bgFileName="kids/kids-banner.jpg"
      />
      <KidsHome />
      <ExploreKids />
    </Layout>
  )
}

export default Kids
