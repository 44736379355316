import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import { StaticImage } from "gatsby-plugin-image"

// Functional components
const KidsHome = () => {
    return (
        
        <div className="projects-area pt-70 pb-70">
            <div className="container"> 

                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="What  do you deeply wants for your  kids?" /> 
                        What  do you deeply want for your  kids?
                    </span>
                    <h2>Our goal is to support kids teaching, inspiring and connecting with society sharing the lovely message to change the world.</h2>
                    <p>We are stronger together. Each child should be given a vision of their place in the world to create a ripple effect in the sea of humanity.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                            <StaticImage
                                    src="../../assets/images/kids/kids5.jpg"
                                    placeholder="blurred"
                                    width={550}
                                    height={325}
                                    alt="Passion for Learning"
                                />

                                <Link to="/passion-for-learning" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/passion-for-learning" >
                                        Passion for Learning
                                    </Link>
                                </h3>
                                <p>Passion for Learning brings together innovative classes specially tailored to current teaching topics through fun and imagination to increase children's confidence and self-esteem.</p>
                                <Link className="default-btn" to="/passion-for-learning">
                                    <i className="flaticon-right"></i> I'm Interested <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <StaticImage
                                    src="../../assets/images/kids/kids6.jpg"
                                    placeholder="blurred"
                                    width={550}
                                    height={325}
                                    alt="Body Connection"
                                />

                                <Link to="/body-connection" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/body-connection">
                                        Body Connection
                                    </Link>
                                </h3>
                                <p>If you want to develop the mind, you must develop the body.
                                Movement, play, and exercise!<br/>
                                Fun movement is the biologically built-in developer of body-mind-feelings awareness and management.</p>
                                <Link className="default-btn" to="/body-connection">
                                    <i className="flaticon-right"></i> I'm Interested <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <StaticImage
                                    src="../../assets/images/kids/i-got-this-feeling.jpg"
                                    placeholder="blurred"
                                    width={550}
                                    height={325}
                                    alt="I Got This Feeling"
                                />

                                <Link to="/contact" state={{page: "kids-i-got-this-feeling"}} className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/contact" state={{page: "kids-i-got-this-feeling"}}>
                                        I Got This Feeling!
                                    </Link>
                                </h3>
                                <p>Emotional intelligence can be exercised, just like intellectual intelligence, through meaningful children’s activities and experiences.<br/>
                                Learning wellbeing tools and singing songs with fun and welcoming teachers!</p>
                                <Link className="default-btn" to="/contact" state={{page: "kids-i-got-this-feeling"}}>
                                    <i className="flaticon-right"></i> I'm Interested <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                            <StaticImage
                                    src="../../assets/images/kids/kids8.jpg"
                                    placeholder="blurred"
                                    width={550}
                                    height={325}
                                    alt="Tribe  Connection"
                                />

                                <Link to="/contact" className="link-btn" state={{page: "kids-tribe-connection"}}>
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/contact" state={{page: "kids-tribe-connection"}}>
                                        Tribe  Connection
                                    </Link>
                                </h3>
                                <p>How can we help children develop social competence - the ability to read emotions, cooperate, make friends, and negotiate conflicts?<br/>
                                Helping your child find their tribe can build self-confidence, help your child feel more comfortable in their own skin and make a new friend!</p>
                                <Link to="/contact" className="default-btn" state={{page: "kids-tribe-connection"}}>
                                    <i className="flaticon-right"></i> I'm Interested <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KidsHome